/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { LogoJsonLd, ProductJsonLd } from 'gatsby-plugin-next-seo'
import getStaticImage from '../hooks/getStaticImage'
import { IS_PROD } from '../constants'

function SEO({ description, lang, meta, title, linkCanonical, schema }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const siteUrl = site.siteMetadata.siteUrl
  const metaDescription = description || site.siteMetadata.description
  const logoCora = `${siteUrl}${getStaticImage('cora-logo.png')}`
  const schemaOrg = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: siteUrl,
    name: 'Cora Conta Digital PJ',
    logo: logoCora,
    email: 'contato@cora.com.br',
    founder: ['Igor Senra', 'Léo Mendes'],
    sameAs: [
      'https://www.instagram.com/cora.bancopj/',
      'https://www.linkedin.com/company/cora-bancopj/',
      'https://www.facebook.com/cora.bancopj/',
    ],
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={title}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: site.siteMetadata.author,
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            name: 'twitter:description',
            content: metaDescription,
          },
          {
            name: 'fb:pages',
            content: '108210520540043',
          },
        ].concat(meta)}
      >
        <link rel="canonical" href={`${siteUrl}/${linkCanonical}`} />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
      </Helmet>
      <LogoJsonLd logo={logoCora} url={siteUrl} />
      {schema.type && (
        <ProductJsonLd
          productName={schema.title}
          images={schema.images}
          description={schema.description}
          reviews={schema.reviews}
          aggregateRating={schema.rating}
        />
      )}
    </>
  )
}

SEO.defaultProps = {
  lang: 'pt-BR',
  meta: [],
  description: '',
  linkCanonical: '',
  schema: {
    title: '',
    images: [],
    type: '',
    description: '',
    reviews: [],
    rating: {},
  },
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  linkCanonical: PropTypes.string,
  schema: PropTypes.object,
}

export default SEO
